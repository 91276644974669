import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './css/invoice.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

// Remove or reduce these constants to prevent forced page breaks
// const MAX_ROWS_PER_PAGE = 25; // Adjust based on your row height
// const LINES_PER_PAGE = 28; // Optimal for A4 with 12pt font

const InvoiceBody = ({saleData})=>{
    const classes = useStyles();
    // Invoice body
  
        /// end 

        // console.log(sale)
         let [sale,saleSet] = useState([])
          let [saleDetails,saleDetailsSet] = useState([])

        useEffect(()=>{
            saleSet(saleData)

            if(saleData.details!=undefined){
                saleDetailsSet(saleData.details)
            }
            },[saleData])


      return(
          <>
        <table className="invoice-table" style={{pageBreakInside: 'auto'}}>
            <thead>
                <tr>
                    <th style={{width: '5%'}}>SL</th>
                    <th style={{width: '45%'}}>Description</th>
                    <th style={{width: '10%'}}>Qty</th>
                    <th style={{width: '15%'}}>Rate</th>
                    <th style={{width: '25%'}}>Total</th>
                </tr>
            </thead>
            <tbody>
                {saleDetails && saleDetails.map((detail, index) => (
                    <tr key={index} className="table-row" style={{height: '6mm', pageBreakInside: 'avoid'}}>
                        <td>{index + 1}</td>
                        <td>{detail.prod_name}</td>
                        <td>{detail.sale_qty}</td>
                        <td>{format(parseFloat(detail.sale_rate).toFixed(2))}</td>
                        <td>{format(parseFloat(detail.sale_prod_total).toFixed(2))}</td>
                    </tr>
                ))}
            </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default InvoiceBody;