import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {API_URL} from '../../config.json';
import InvoiceBody from './quotation_invoice_body'
import {currentDateTime, dateTimeFormat} from '../../lib/functions'
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import './css/invoice.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

// Add this constant outside the component
const MAX_ROWS_PER_PAGE = 25; // Adjust based on your row height

class PrintAbleSection extends React.Component {
      
  constructor(props) {
    super(props); 
  }

  state = {
    sale:[],
    customerDue:0
  }

  componentDidMount() {
    this.getsale()
  }
   
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saleId != this.props.saleId) {
        this.getsale()
    }
  }

   getsale = async()=>{
      await axios.post(`${API_URL}/api/get-quotation-with-details`,{reqPayload:{saleId:this.props.saleId,from:'invoice'}},{headers:{'auth-token':this.props.authInfo.token}}).then(res=>{
        this.setState({sale:res.data.message[0]});
      });
  }

    convertNumberToWords =  (amountToWord)=>{
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    let amount = amountToWord == null ? '0.00' : amountToWord.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        let value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Hundred Thousand  ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string + ' only';
}


  render(){
    let {sale,customerDue}  = this.state;

    return(
      <div style={{padding:'0', marginTop: '0', overflow: 'visible'}} >
        {sale != null ?(
          <>
            <h3 className="invoice-title" style={{ 
              margin: '0',
              padding: '0',
              pageBreakAfter: 'avoid'
            }}>Quotation Invoice</h3>
            
            <Grid container spacing={0} style={{ 
              margin: '0',
              padding: '0',
              pageBreakInside: 'avoid',
              pageBreakAfter: 'avoid'
            }}>
              <Grid item xs={12} style={{ margin: '0', padding: '2px 0' }}>
                  <strong>Customer ID : </strong> <span>{ sale.customer_code }</span><br/>
                  <strong>Customer Name : </strong> <span>{ sale.customer_name }</span><br/>
                  <strong>Institution Name : </strong> <span>{ sale.customer_institution_name }</span><br/>
                  <strong>Customer Address : </strong> <span>{ sale.customer_address }</span><br/>
                  <strong>Customer Mobile : </strong> <span>{ sale.customer_mobile_no }</span><br/>
              </Grid>
              <Grid item xs={12} sm={12} style={{textAlign:'right'}}>  
                  <strong>Quotation By : </strong> <span>{ sale.employee_name }</span><br/>
                  <strong>Invoice No : </strong> <span>{ sale.sale_invoice }</span><br/>
                  <strong> Date & Time  : </strong> <span>{ moment(sale.sale_created_isodt).format(dateTimeFormat)  }</span><br/>
              </Grid>
            </Grid>

            <div style={{overflow: 'visible', pageBreakBefore: 'avoid'}}>
              <InvoiceBody saleData={sale} />
            </div>

            <Grid container style={{ 
                marginTop: '10px',
                padding: '0 5px'
            }}>
                  <Grid xs={6} sm={6} style={{marginTop: '2px'}}>
                            <table style={{width:'100%'}}>
                              <tbody>
                                <tr>
                                  <td>Sub Total : </td>
                                  <td style={{textAlign:"right"}}> {format(parseFloat(sale.sale_subtotal_amount).toFixed(2))} </td>
                                </tr>
                                <tr>
                                  <td>Transport Cost : </td>
                                  <td style={{textAlign:"right"}}> {format(parseFloat(sale.sale_transport_cost).toFixed(2))} </td>
                                </tr>
                                <tr>
                                  <td>Vat :</td>
                                  <td style={{textAlign:"right"}}> {format(parseFloat(sale.sale_vat_amount).toFixed(2))} </td>
                                </tr>
                                <tr>
                                  <td>Discount :</td>
                                  <td style={{textAlign:"right"}}> {format(parseFloat(sale.sale_discount_amount).toFixed(2))} </td>
                                </tr>
                                
                                <tr>
                                <td style={{background: '#ccc',textAlign:"right",width:'100%'}}></td>
                                  <td style={{background: '#ccc',textAlign:"right",width:'100%'}}></td>
                                </tr>
                                <tr>
                                  <td>Total : </td> 
                                  <td style={{textAlign:"right"}}>  {format(parseFloat(sale.sale_total_amount).toFixed(2))} </td>
                                </tr>
                              </tbody>
                            </table>
                  </Grid>

                  <Grid xs={12} sm={12}>
                        <p>In Word : {this.convertNumberToWords(sale.sale_total_amount)} </p>
                        <p>Note : </p>
                  </Grid>

            </Grid>
          </>
        ) : ''}
      </div>
    )
  }
}


class ComponentToPrint extends React.Component {

   
  constructor(props) {
    super(props);  
     }
  state = {
    authInfo:this.props.authInfo,
    saleId:this.props.saleId
  }


  render() {
      let institution =   this.props.institution;
    let a4css = `
    .a4 {
        font-size: 11px;
        line-height: 1;
    }
    .invoice-title {
        font-size: 14px;
        margin: 0 !important;
        padding: 2px 0;
    }
    .invoice-desc p {
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    .print-content {
        padding-top: 0;
        height: auto !important;
        overflow: visible !important;
    }
    .header-section {
        display: flex;
        flex-direction: column;
    }
    @media print {
        html, body {
            margin: 0;
            padding: 0;
            height: auto !important;
            overflow: visible !important;
        }
        .header-and-content {
            page-break-inside: avoid !important;
        }
        .invoice-head, .blue-line, .invoice-title, .content-area {
            page-break-inside: avoid !important;
            page-break-before: avoid !important;
            page-break-after: avoid !important;
        }
        .invoice-table {
            page-break-inside: auto !important;
        }
        tr { 
            page-break-inside: avoid !important;
        }
        thead {
            display: table-header-group !important;
        }
        tfoot {
            display: table-footer-group !important;
        }
        * {
            overflow: visible !important;
        }
    }
`;

    let hafa4css = `
    .hafa4 { 
      width:500px !important;
  }
  .hafa4 body, table{
      font-size: 14px;
  }
    `

    let poscss = `
    .pos body{
      font-size: 10px;
  }
  .pos body, table{
      font-size: 10px;
  }
    `


   
    return (
      <div className='print-source'>
        {institution && institution.pro_print_type === 'a4' ? (
          <html lang="en">
            <head>
               <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <title>Sales Invoice</title>
                <style>
                   {a4css}
                   {`
                       .print-content {
                           position: relative;
                           overflow: visible !important;
                           height: auto !important;
                       }
                       .header-and-content {
                           page-break-inside: avoid !important;
                       }
                       .invoice-head {
                           margin-bottom: 0;
                       }
                       body {
                           margin: 0;
                           padding: 0;
                           overflow: visible !important;
                           height: auto !important;
                       }
                       table { 
                           page-break-inside: auto !important;
                           overflow: visible !important;
                       }
                       tr { 
                           page-break-inside: avoid !important;
                       }
                       thead {
                           display: table-header-group !important;
                       }
                       * {
                           overflow: visible !important;
                       }
                   `}
                </style>
            </head>
            <body className="a4" style={{padding:'0', overflow: 'visible'}}>
            <div className="print-content" style={{overflow: 'visible'}}>
            <div className="header-and-content" style={{pageBreakInside: 'avoid'}}>
            <div className="invoice-head" style={{padding:'0', margin:'0', pageBreakAfter: 'avoid'}}>
               <div className="invoice-logo">
               <img 
                 alt={institution.pro_name} 
                 src={`${API_URL}/${institution.pro_logo}`} 
                 style={{width:'200px', height:'auto', maxHeight: '60px'}} 
               /> 
                

               </div>
                <div className="invoice-desc">
                  
                  <p>Wideoptions Trading Company Limited</p>
                  <p>P.O. Box  185 - 60400 Chuka</p>
                  <p>Tel: 0713138138 , 0700674006</p>
                  <p>Email: Info@wideoptions.co.ke</p>
                  <p style={{ margin: '0', color: '#0077be', fontWeight: 'bold' }}>www.wideoptions.co.ke</p>
                </div>
            </div>
            <div className="blue-line" style={{margin:'0', height:'1px', pageBreakAfter: 'avoid', pageBreakBefore: 'avoid'}}></div>
            
            <div style={{marginTop:'0', paddingTop:'0', overflow:'visible', pageBreakBefore: 'avoid'}} className="content-area">
              <PrintAbleSection authInfo={this.state.authInfo} saleId={this.props.saleId} />
            </div>
            </div>
            <div className="footer-container" style={{pageBreakInside: 'avoid'}}>
              <div className="invoice-footer" style={{ 
                  paddingTop: '10px',
                  borderTop: '1px solid #222',
                  marginTop: '20px'
              }}>
                  <p style={{float:'left',width:'150px',textAlign:'center'}}>Received By</p>
                  <p style={{float:'right',marginRight:'10px',width:'150px',textAlign:'center'}}>Authorized By</p>
                  <div style={{clear: 'both'}}></div>
              </div>
              <div className="invoice-footer-bottom" style={{ marginTop: '10px' }}>
                  <p>Print Date & Time: {new Date().toLocaleString()}</p>
              </div>
            </div>
            </div>
            </body>
          </html>
        ) : institution && institution.pro_print_type === '1/2a4' ? (
          <html lang="en">
            <head>
               <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <title>Sales Invoice</title>
                <style > 
                   {hafa4css}
                   {`
                       body, html {
                           overflow: visible !important;
                           height: auto !important;
                       }
                       * {
                           overflow: visible !important;
                       }
                       table { 
                           page-break-inside: auto !important;
                       }
                       tbody { 
                           page-break-inside: auto !important;
                       }
                       tr { 
                           page-break-inside: avoid !important;
                       }
                       thead {
                           display: table-header-group !important;
                       }
                   `}
                </style>
            </head>
            <body className="hafa4" style={{padding:'5px', overflow: 'visible'}}>
            <div className="header-and-content" style={{overflow: 'visible'}}>
            <div className="invoice-head">
               <div className="invoice-logo">
               <img 
                 alt={institution.pro_name} 
                 src={`${API_URL}/${institution.pro_logo}`} 
                 style={{width:'250px',height:'auto'}} 
               /> 
                

               </div>
                <div className="invoice-desc">
                  
                  <p>Wideoptions Trading Company Limited</p>
                  <p>P.O. Box  185 - 60400 Chuka</p>
                  <p>Tel: 0713138138 , 0700674006</p>
                  <p>Email: Info@wideoptions.co.ke</p>
                  <p style={{ margin: '0', color: '#0077be', fontWeight: 'bold' }}>www.wideoptions.co.ke</p>
                </div>
          </div>
          <div className="blue-line"></div>
            

          <div style={{ marginTop: '5px', overflow: 'visible' }}>
            <PrintAbleSection authInfo={this.state.authInfo} saleId={this.props.saleId} />
          </div>
          </div>
            <div style={{position:'fixed',bottom:'0px',width:'500px',padding:'5px'}}>
            <div className="invoice-footer" >
                      <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                 
                       <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                </div>
                <div className="invoice-footer-bottom" style={{width:'500px'}} >
                      <p style={{float:'left'}}>
                          Print Date & Time: {new Date().toLocaleString()}
                     </p>
                </div>
                </div>
              </body>
              </html>
        ) : institution && institution.pro_print_type === 'pos' ? (
          <html lang="en">
            <head>
               <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <title>Sales Invoice</title>
                <style >
                   {poscss}
                   {`
                       body, html {
                           overflow: visible !important;
                           height: auto !important;
                       }
                       * {
                           overflow: visible !important;
                       }
                       table { 
                           page-break-inside: auto !important;
                       }
                       tbody { 
                           page-break-inside: auto !important;
                       }
                       tr { 
                           page-break-inside: avoid !important;
                       }
                       thead {
                           display: table-header-group !important;
                       }
                   `}
                </style>
            </head>
            <body className="pos" style={{padding:'5px', overflow: 'visible'}}>
            <div className="header-and-content" style={{overflow: 'visible'}}>
            <div className="invoice-head">
               <div className="invoice-logo">
               <img 
                 alt={institution.pro_name} 
                 src={`${API_URL}/${institution.pro_logo}`} 
                 style={{width:'250px',height:'auto'}} 
               /> 
                

               </div>
                <div className="invoice-desc">
                  
                  <p>Wideoptions Trading Company Limited</p>
                  <p>P.O. Box  185 - 60400 Chuka</p>
                  <p>Tel: 0713138138 , 0700674006</p>
                  <p>Email: Info@wideoptions.co.ke</p>
                  <p style={{ margin: '0', color: '#0077be', fontWeight: 'bold' }}>www.wideoptions.co.ke</p>
                </div>
          </div>
          <div className="blue-line"></div>
            

          <div style={{ marginTop: '5px', overflow: 'visible' }}>
            <PrintAbleSection authInfo={this.state.authInfo} saleId={this.props.saleId} />
          </div>
          </div>
            <div style={{position:'fixed',bottom:'0px',width:'100%',padding:'5px'}}>
            <div className="invoice-footer" >
                      <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                 
                       <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                </div>
                <div className="invoice-footer-bottom" >
                      <p style={{float:'left'}}>
                          Print Date & Time: {new Date().toLocaleString()}
                     </p>
                </div>
                </div>
              </body>
              </html>
        ) : null}
      </div>
    );
  }
}

let SalesInvoice = ({authInfo,saleId,institution})=>{
  let componentRef = useRef()

  return (
    <>
            <Grid container>
                  <Grid item xs={12} sm={8} className={"invoice-section"}>
                    {/* Main Grid Start */}
                    <div>
                    <ReactToPrint
                      trigger={() => <PrintIcon  style={{cursor:'pointer'}} />}
                      content={() => componentRef}
                    />

    <ComponentToPrint ref={el => (componentRef = el)} authInfo={authInfo} saleId={saleId} institution={institution} />


                    <PrintAbleSection  authInfo={authInfo} saleId={saleId} />

                  </div>
                  </Grid>
            </Grid>
    </>
  )
}



  const mapStateToPops = (state)=>{
    return {
      authInfo:state.authInfoReducer
    }
}
export default connect(mapStateToPops,{})(SalesInvoice);